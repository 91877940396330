
import { defineComponent } from 'vue';
import { useStore } from '@/store';
import PasswordForm from '@/components/PasswordForm.vue';

export default defineComponent({
  name: 'ChangePasswordDialog',
  components: {
    PasswordForm,
  },
  props: {
    accountId: {
      type: String,
      required: true
    },
    accountName: {
      type: String,
      required: true
    },
  },

  setup() {
    const store = useStore();
    return { store };
  },

  computed: {
    displayCurrentPassword(): boolean {
      return this.store.state.jwt.data?.id === this.accountId;
    },
  }
});
