<template>
  <div :class="{ 'card': displayCard }">
    <div v-if="displayCard" class="card-header">
      <span>Change password</span>
    </div>
    <div :class="{ 'card-body': displayCard }">
      <form novalidate>
        <div v-if="displayCurrentPassword" class="form-group">
          <label for="currentPassword">Current password</label>
          <input v-model.trim="currentPassword" type="password" class="form-control" :class="{ 'is-invalid': isCurrentPasswordInvalid }" id="currentPassword" placeholder="Enter current password" required>
          <div class="invalid-feedback">
            Please provide your current password with at least 8 characters.
          </div>
        </div>
        <div class="form-group">
          <label for="newPassword">New password</label>
          <input v-model.trim="newPassword" type="password" class="form-control" :class="{ 'is-invalid': isNewPasswordInvalid }" id="newPassword" placeholder="Enter new password" required>
          <div class="invalid-feedback">
            Please provide a new password with at least 8 characters.
          </div>
        </div>
        <div class="form-group">
          <label for="newPasswordConfirm">New password confirmation</label>
          <input v-model.trim="newPasswordConfirm" type="password" class="form-control" :class="{ 'is-invalid': isNewPasswordConfirmInvalid }" id="newPasswordConfirm" placeholder="Enter new password again" required>
          <div v-if="isNewPasswordNotMatching" class="invalid-feedback">
            Password confirmation does not match.
          </div>
          <div v-else class="invalid-feedback">
            Please provide the new password again for confirmation.
          </div>
        </div>

        <div v-if="isSuccess" class="alert alert-success" role="alert">
          Password successfully updated.
        </div>
        <HttpExtendedResultAlert v-if="error" v-bind:result="error" />

        <button type="submit" class="btn btn-primary" @click.prevent="onSave()" v-bind:disabled="saveDisabled">
          <font-awesome-icon v-if="loading" icon="spinner" spin />
          Save
        </button>
      </form>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useStore } from '@/store';
import { ActionTypes } from '@/store/action-types';
import { ChangePasswordModel } from '@/models/change-password-model';
import HttpExtendedResultAlert from '@/components/HttpExtendedResultAlert.vue';
import { HttpExtendedResult } from '@/services/http-extended-result';

export default defineComponent({
  name: 'PasswordForm',
  components: {
    HttpExtendedResultAlert,
  },
  props: {
    accountId: {
      type: String,
      required: true
    },
    displayCard: {
      type: Boolean,
      required: false,
      default: true
    },
    displayCurrentPassword: {
      type: Boolean,
      required: false,
      default: true
    },
  },

  setup() {
    const store = useStore();
    return { store };
  },

  data: () => ({
    currentPassword: '',
    newPassword: '',
    newPasswordConfirm: '',

    needsValidation: false,
    isCurrentPasswordInvalid: false,
    isNewPasswordInvalid: false,
    isNewPasswordConfirmInvalid: false,
    isNewPasswordNotMatching: false,

    loading: false,
    isSuccess: false,
    error: undefined as HttpExtendedResult | undefined,
  }),

  computed: {
    saveDisabled(): boolean { return this.loading || (this.displayCurrentPassword && this.currentPassword.length == 0) ||
      this.newPassword.length == 0 || this.newPasswordConfirm.length == 0; },
  },

  watch: {
    accountId()          { this.initForm(); },
    currentPassword()    { this.validateForm(); },
    newPassword()        { this.validateForm(); },
    newPasswordConfirm() { this.validateForm(); },
  },

  methods: {
    onSave() {
      this.needsValidation = true;

      if (this.validateForm()) {
        this.loading = true;
        this.isSuccess = false;
        this.error = undefined;
        const payload = new ChangePasswordModel(this.accountId, this.currentPassword, this.newPassword);

        if (this.displayCurrentPassword == false)
          payload.currentPassword = payload.newPassword;

        this.store.dispatch(ActionTypes.UPDATE_ACCOUNT_PASSWORD, payload).then(
          () => {
            this.initForm();
            this.isSuccess = true;
          },
          error => {
            this.loading = false;
            this.error = error;
          });
      }
    },

    validateForm(): boolean {
      if (this.needsValidation == false)
        return false;

      this.isCurrentPasswordInvalid = this.needsValidation && this.currentPassword.length < 8;
      this.isNewPasswordInvalid = this.needsValidation && this.newPassword.length < 8;

      this.isNewPasswordNotMatching = this.newPasswordConfirm.length > 0 && this.newPassword !== this.newPasswordConfirm;

      this.isNewPasswordConfirmInvalid = this.needsValidation && (this.isNewPasswordNotMatching || this.newPasswordConfirm.length < 8);

      return (!this.displayCurrentPassword || !this.isCurrentPasswordInvalid) && !this.isNewPasswordInvalid && !this.isNewPasswordNotMatching;
    },

    initForm() {
      this.loading = false;
      this.needsValidation = false;
      this.isSuccess = false;
      this.currentPassword = this.newPassword = this.newPasswordConfirm = '';
      this.error = undefined;
    },
  },
});
</script>
