
import { defineComponent } from 'vue';
import { useStore } from '@/store';
import { ActionTypes } from '@/store/action-types';
import { ChangePasswordModel } from '@/models/change-password-model';
import HttpExtendedResultAlert from '@/components/HttpExtendedResultAlert.vue';
import { HttpExtendedResult } from '@/services/http-extended-result';

export default defineComponent({
  name: 'PasswordForm',
  components: {
    HttpExtendedResultAlert,
  },
  props: {
    accountId: {
      type: String,
      required: true
    },
    displayCard: {
      type: Boolean,
      required: false,
      default: true
    },
    displayCurrentPassword: {
      type: Boolean,
      required: false,
      default: true
    },
  },

  setup() {
    const store = useStore();
    return { store };
  },

  data: () => ({
    currentPassword: '',
    newPassword: '',
    newPasswordConfirm: '',

    needsValidation: false,
    isCurrentPasswordInvalid: false,
    isNewPasswordInvalid: false,
    isNewPasswordConfirmInvalid: false,
    isNewPasswordNotMatching: false,

    loading: false,
    isSuccess: false,
    error: undefined as HttpExtendedResult | undefined,
  }),

  computed: {
    saveDisabled(): boolean { return this.loading || (this.displayCurrentPassword && this.currentPassword.length == 0) ||
      this.newPassword.length == 0 || this.newPasswordConfirm.length == 0; },
  },

  watch: {
    accountId()          { this.initForm(); },
    currentPassword()    { this.validateForm(); },
    newPassword()        { this.validateForm(); },
    newPasswordConfirm() { this.validateForm(); },
  },

  methods: {
    onSave() {
      this.needsValidation = true;

      if (this.validateForm()) {
        this.loading = true;
        this.isSuccess = false;
        this.error = undefined;
        const payload = new ChangePasswordModel(this.accountId, this.currentPassword, this.newPassword);

        if (this.displayCurrentPassword == false)
          payload.currentPassword = payload.newPassword;

        this.store.dispatch(ActionTypes.UPDATE_ACCOUNT_PASSWORD, payload).then(
          () => {
            this.initForm();
            this.isSuccess = true;
          },
          error => {
            this.loading = false;
            this.error = error;
          });
      }
    },

    validateForm(): boolean {
      if (this.needsValidation == false)
        return false;

      this.isCurrentPasswordInvalid = this.needsValidation && this.currentPassword.length < 8;
      this.isNewPasswordInvalid = this.needsValidation && this.newPassword.length < 8;

      this.isNewPasswordNotMatching = this.newPasswordConfirm.length > 0 && this.newPassword !== this.newPasswordConfirm;

      this.isNewPasswordConfirmInvalid = this.needsValidation && (this.isNewPasswordNotMatching || this.newPasswordConfirm.length < 8);

      return (!this.displayCurrentPassword || !this.isCurrentPasswordInvalid) && !this.isNewPasswordInvalid && !this.isNewPasswordNotMatching;
    },

    initForm() {
      this.loading = false;
      this.needsValidation = false;
      this.isSuccess = false;
      this.currentPassword = this.newPassword = this.newPasswordConfirm = '';
      this.error = undefined;
    },
  },
});
