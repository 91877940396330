
import { defineComponent } from 'vue';
import { useStore } from '@/store';
import { ActionTypes } from '@/store/action-types';
import { AccountModel } from '@/models/account-model';
import { RegistrationModel } from '@/models/registration-model';
import HttpExtendedResultAlert from '@/components/HttpExtendedResultAlert.vue';
import { HttpExtendedResult } from '@/services/http-extended-result';
import ChangePasswordDialog from '@/components/ChangePasswordDialog.vue';
import { setFocus } from '@/services/dom-tools';

export default defineComponent({
  name: 'AccountForm',
  emits: ['savedone'],
  components: {
    HttpExtendedResultAlert,
    ChangePasswordDialog,
  },
  props: {
    item: {
      type: AccountModel,
      required: true
    },
  },

  setup() {
    const store = useStore();
    return { store };
  },

  data: () => ({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    role: '',

    isPasswordInvalid: false,
    loading: false,
    error: undefined as HttpExtendedResult | undefined,
  }),

  watch: {
    'item': {
      handler: function(newItem: AccountModel) {
        this.firstName = newItem.firstName;
        this.lastName = newItem.lastName;
        this.email = newItem.email;
        this.role = newItem.role;

        this.error = undefined;
      },
      //deep: true,
      immediate: true
    },
  },

  computed: {
    editMode(): boolean { return this.item.id.length > 0; },
    userIsAdmin(): boolean { return this.store.getters.userIsAdmin; },
    saveDisabled(): boolean { return this.loading || (this.item.firstName == this.firstName &&
      this.item.lastName == this.lastName && this.item.email == this.email && this.item.role == this.role && this.password.length === 0); },
    accountName(): string { return this.item.firstName + ' ' + this.item.lastName; },
  },

  methods: {
    onSave() {
      this.loading = true;
      this.error = undefined;

      if (this.editMode) {
        const payload = new AccountModel(this.item.id, this.role, this.email, this.firstName, this.lastName);
        this.updateAccount(payload);
      }
      else {
        const payload = new RegistrationModel(this.email, this.password, this.firstName, this.lastName);
        this.addAccount(payload);
      }
    },

    addAccount(payload: RegistrationModel) {
      this.store.dispatch(ActionTypes.ADD_ACCOUNT, payload).then(
        account => this.parseResponse(account, undefined),
        error => this.parseResponse(undefined, error));
    },

    updateAccount(payload: AccountModel) {
      this.store.dispatch(ActionTypes.UPDATE_ACCOUNT, payload).then(
        account => this.parseResponse(account, undefined),
        error => this.parseResponse(undefined, error));
    },

    parseResponse(account?: AccountModel, error?: HttpExtendedResult) {
      this.loading = false;
      this.error = error;

      if (account)
        this.$emit('savedone', account);
    },

    onChangePassword() {
      const defaultField = this.store.state.jwt.data?.id === this.item.id ? 'currentPassword' : 'newPassword';

      setTimeout(() => setFocus(defaultField), 500);
    },
  },
});
