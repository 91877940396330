<template>
  <div class="card">
    <div class="card-header" :class="{ 'with-button': showAddHeader }">
      <div class="d-flex justify-content-between">
        <div class="my-auto"><font-awesome-icon v-bind:icon="icon" /> {{ title }}</div>
        <div v-if="showAddHeader"><a class="plus-button" href="#" @click.prevent="$emit('addclicked')"><font-awesome-icon v-bind:icon="addIcon" /></a></div>
      </div>
    </div>

    <div v-if="loading" class="p-4">
      <font-awesome-icon icon="spinner" spin /> {{ loadMessage }}
    </div>

    <div v-else-if="items.length === 0" class="p-4">
      <div>{{ addMessage }}</div>
      <div class="d-flex justify-content-center">
        <button v-if="showAddBody" type="button" class="btn btn-primary mt-2" @click.prevent="$emit('addclicked')">Add</button>
      </div>
    </div>

    <ul v-else class="list-group list-group-flush">
      <li class="list-group-item link-item"
        v-for="item in items"
        v-bind:key="item.id"
        @click="$emit('itemclicked', item)">
        <div class="d-flex justify-content-between align-box">
          <div class="my-auto">{{ item.title }}</div>
          <div v-if="showEdit" class="edit-button"><a class="edit-icon" href="#" @click.stop="$emit('editclicked', item)"><font-awesome-icon v-bind:icon="editIcon" /></a></div>
        </div>
        <div v-if="showSubtitle && item.subTitle">
          <small class="text-muted">{{ item.subTitle }}</small>
        </div>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { ListCardItem } from '@/models/list-card-item';

export default defineComponent({
  name: 'ListCard',
  emits: ['addclicked', 'editclicked', 'itemclicked'],
  props: {
    title: { type: String, required: true },
    icon: { type: String, required: true },
    items: { type: Array as () => ListCardItem[], required: true },
    showEdit: { type: Boolean, require: false },
    showAddHeader: { type: Boolean, require: false },
    showAddBody: { type: Boolean, require: false },
    showSubtitle: { type: Boolean, require: false, default: true },
    loadMessage: { type: String, require: false },
    addMessage: { type: String, require: false },
    loading: { type: Boolean, require: false },
    editIcon: { type: String, required: false, default: 'edit' },
    addIcon: { type: String, required: false, default: 'plus-circle' },
  },
});
</script>

<style scoped lang="scss">
.card-header {
  padding: 0.775rem 1.25rem;
  
  &.with-button {
    padding: .4rem 1.25rem;
  }

  .plus-button {
    font-size: 1.5rem;
    color: #5F4B41;
    margin-right: .2rem;
  }
}

.list-group-item.link-item {
  padding: .4rem 1.25rem;
  min-height: 50px;

  &:hover {
      cursor: pointer;
      background-color: #B4AFA5;
  }

  .align-box {
    min-height: 36px;
  }

  .edit-icon {
    font-size: 1.5rem;
    color: #5F4B41;
  }

  .edit-button {
    display: none;
  }

  &:hover .edit-button {
    display: block;
  }
}
</style>
