<template>
  <div class="modal fade" id="changePasswordModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Change password</h5>
          <button type="button" class="close" aria-label="Close" data-dismiss="modal">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="d-flex justify-content-center">
            <h4>{{ accountName }}</h4>
          </div>

          <PasswordForm v-bind:accountId="accountId"
            v-bind:displayCard="false" v-bind:displayCurrentPassword="displayCurrentPassword" />
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useStore } from '@/store';
import PasswordForm from '@/components/PasswordForm.vue';

export default defineComponent({
  name: 'ChangePasswordDialog',
  components: {
    PasswordForm,
  },
  props: {
    accountId: {
      type: String,
      required: true
    },
    accountName: {
      type: String,
      required: true
    },
  },

  setup() {
    const store = useStore();
    return { store };
  },

  computed: {
    displayCurrentPassword(): boolean {
      return this.store.state.jwt.data?.id === this.accountId;
    },
  }
});
</script>
