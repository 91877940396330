<template>
  <div class="container">
    <h1>Accounts</h1>

    <div class="row">
      <div class="col-md mb-4">
        <ListCard title="Users" icon="users"
                  loadMessage="Loading users"
                  v-bind:showAddHeader="true" v-bind:showEdit="false"
                  v-bind:loading="loading"
                  v-bind:items="list"
                  @itemclicked="onItemClicked"
                  @addclicked="onAddClicked" />
      </div>

      <div class="col-md mb-4">
        <AccountForm v-if="item" v-bind:item="item"
          @savedone="onSaveDone" />
      </div>
    </div>    
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useStore } from '@/store';
import { ActionTypes } from '@/store/action-types';
import ListCard from '@/components/ListCard.vue';
import { ListCardItem } from '@/models/list-card-item';
import AccountForm from '@/components/AccountForm.vue';
import { AccountModel } from '@/models/account-model';
import AccountsService from '@/services/accounts-service';
import { setFocus } from '@/services/dom-tools';

export default defineComponent({
  name: 'Accounts',
  components: {
    ListCard,
    AccountForm,
  },

  setup() {
    const store = useStore();
    return { store };
  },

  mounted() {
    this.store.dispatch(ActionTypes.LOAD_ALL_DATA).then(
      () => setFocus('firstName'),
      () => this.$router.push({ name: "Signin" })
    );
  },

  data: () => ({
    item: undefined as AccountModel | undefined,
  }),

  computed: {
    loading(): boolean { return this.store.state.loadingData; },
    list(): ListCardItem[] { return this.store.getters.accountsList; },
  },

  methods: {
    getAccountById(id: string): AccountModel {
      return this.store.getters.getAccountById(id);
    },

    onItemClicked(item: ListCardItem) {
      this.item = this.getAccountById(item.id);
      setFocus('firstName');
    },

    onAddClicked() {
      this.item = AccountsService.createNewAccount();
      setFocus('firstName');
    },

    onSaveDone(item: AccountModel) {
      this.item = item;
    },
  },
});
</script>
