
import { defineComponent } from 'vue';
import { ListCardItem } from '@/models/list-card-item';

export default defineComponent({
  name: 'ListCard',
  emits: ['addclicked', 'editclicked', 'itemclicked'],
  props: {
    title: { type: String, required: true },
    icon: { type: String, required: true },
    items: { type: Array as () => ListCardItem[], required: true },
    showEdit: { type: Boolean, require: false },
    showAddHeader: { type: Boolean, require: false },
    showAddBody: { type: Boolean, require: false },
    showSubtitle: { type: Boolean, require: false, default: true },
    loadMessage: { type: String, require: false },
    addMessage: { type: String, require: false },
    loading: { type: Boolean, require: false },
    editIcon: { type: String, required: false, default: 'edit' },
    addIcon: { type: String, required: false, default: 'plus-circle' },
  },
});
